/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, Image, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-10vs5r3 --style3 --full pb--16" name={"uvod"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --center" anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box fs--24" content={"<span style=\"color: rgb(255, 255, 255);\">Lihovarska 1378/11 Ostrava</span><br>"}>
              </Subtitle>

              <Title className="title-box fs--128 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: rgb(255, 255, 255);\">Profesionální autoslužby v Ostravě&nbsp;</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--20 mt--25" url={"https://autoservisblaza.cz/kontakt"} href={"https://autoservisblaza.cz/kontakt"} content={"Kde nás najdete"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--60" name={"uvod-2  "}>
          
          <ColumnWrap className="column__flex --left el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":526}}>
              
              <Subtitle className="subtitle-box" content={"Autoservis - Geometrie - Pneuservis - Klimatizace - Nástřiky podvozku - Diagnostika - Odtahová služba - Autopůjčovna - Řešení pojistných událostí - čištění DPF filtru a strojní regenerace - výkup vozidel&nbsp;<br>"}>
              </Subtitle>

              <Title className="title-box fs--62" content={"Pro Vaše auto uděláme vše, co budeme moct."}>
              </Title>

              <Text className="text-box fs--18" content={"Jsme nezávislý autoservis v Ostravě. Specializujeme se výhradně na rychlé servisní práce a speciální autoslužby jako například: <br>Čištění DPF filtru a strojní regeneraci<br>- Rychloservis - brzdy,čepy,tlumiče,výfuky,oleje<br>- Geometrie kol - osobní vozidla, dodávky i karavany<br>- Klimatizace - vozidla i stroje&nbsp;<br>- Antikorozni nástřiky podvozku a dutin&nbsp;<br>- Diagnostika<br>- Čištění klimatizací ozonem<br>- Výměna autoskel&nbsp;<br>- Výměna a diagnostika autobaterii&nbsp;<br>- Kontrola stavu vozidla&nbsp;<br>- Výměna náplní&nbsp;<br>- Příprava a zajištění STK a emisí&nbsp;<br>- Odtahová služba&nbsp;<br>- Výkup vozidel&nbsp;<br><br>"}>
              </Text>

              <Button className="btn-box btn-box--shape5" url={"https://autoservisblaza.cz/sluzby"} href={"https://autoservisblaza.cz/sluzby"} content={"Nabídka služeb"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}}>
              
              <Image src={"https://cdn.swbpg.com/t/i/template/156/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/i/template/156/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-1_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"aqo55b3nc0c"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={""}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

              <Text className="text-box" content={"Bláža steel s.r.o.<br>Lihovarska 1378/11, Ostrava areál bánskych strojíren&nbsp;<br><a href=\"+420777321587\">+420 777 321 587&nbsp;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cdgsc093wgm"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Diagnostika"}>
              </Title>

              <Text className="text-box" content={"S mobilní diagnostikou&nbsp; mohou naši&nbsp; pracovníci, kteří vlastní profesní certifikáty odbornosti, usednout také za volant Vašeho vozidla a snímat data i přímo při zkušební jízdě.\n\nPomocí&nbsp; konektoru ve vozidle a naší diagnostikou je&nbsp; možné se&nbsp; spojit s řídící jednotkou . S tímto vybavením je možné přečíst informace o případných závadách, provádět různá nastavení nebo číst některé další hodnoty, údaje z jednotlivých čidel a to i za provozu vozidla."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"iavdk0dcj1"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Příprava a provedení STK"}>
              </Title>

              <Text className="text-box" content={"Vaše vozidlo zkontrolujeme a připravíme na provedení STK. V případě zájmu zařídíme kontrolu STK za vás. Vozidlo zanecháte v servisu a po provedení kontroly si vozidlo s platnou STK vyzvednete."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sb4a08lzu7"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Čištění klimatizace ozonem&nbsp;"}>
              </Title>

              <Text className="text-box" content={"Provádíme čištění a dezinfekci klimatizace ozonem na počkání. Potřebujete se zbavit nepříjemného zápachu z vaší klimatizace tak pak doporučujeme výměnu kabinového filtru a dezinfekci ozonem. objednejte se ještě dnes na dezinfekci Vaší autoklimatizace&nbsp; a odstranění všech nepříjemných zápachů a&nbsp; baterii.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"184uohn75uc"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Odtahová služba&nbsp;"}>
              </Title>

              <Text className="text-box" content={"Provozujeme vlastní odtahovou službu a uskladnění vozidel v nonstop hlídaném areálu.&nbsp; Odtah do našeho autoservisu nebo našeho autovrakoviště&nbsp; je zdarma."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"riedzlc4iye"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Autoservis&nbsp;"}>
              </Title>

              <Text className="text-box" content={"Autoservis Bláža Ostrava - Radvanice . Jsme nezávislý autoservis osobních a dodávkových vozidel .<br>Specializujeme se na služby jako například : Strojní čištění a regeneraci filtru pevných částic DPF, Měření a seřízení geometrie kol na osobních a užitkových vozidlech včetně obytných vozidel a karavanů. Antikorozní nástřik podvozku a dutin. Servis čištění a plnění klimatizací. a klasický servis vozidel brzdy, čepy, tlumiče, výfuky, náplně, pneuservis, a jiné servisní úkony.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"3j0pbje59ad"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Antikorozni nástrik podvozku a dutin&nbsp;"}>
              </Title>

              <Text className="text-box" content={"Provádíme vysoce kvalitní antikorozní nástřik podvozku a dutin. Vysoce účiná&nbsp; ochrana podvozku vozidla proti korozi. Antikorozní nástřiky podvozku a dutin a kompletní protikorozní ochranu a zastavení koroze&nbsp; provádíme&nbsp; více než 10 let.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fj93gmggvon"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Geometrie kol - seřízení a měření&nbsp;<br>"}>
              </Title>

              <Text className="text-box" content={"Provádíme profesionální laserové seřízení geometrie kol. Součástí geometrie je i test naměřených hodnot a vytištění protokolu o měření . Měření i seřízení probíhá na nejmodernější přístroji italské značky Ravaglioli pro trojrozměrný systém měření geometrie kol.&nbsp;Měření a seřízení geometrie kol na osobních i dodávkových vozidlech  včetně obytných vozidel&nbsp; a offroadu.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mvzpofo04v"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Čištění DPF filtru&nbsp; - stojní metodou"}>
              </Title>

              <Text className="text-box" content={"Strojní čištění filtrů pevných částic. Rozsvítila se vám kontrolka filtru pevných částic a vaše vozidlo nechce přirozeně a automaticky regenerovat filtr DPF nebo vozidlo už jede v nouzovém režimu ? Nejspíše bude třeba provést strojní regeneraci a čištění filtru pevných částic.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" name={"sluzby"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--3 pb--0 pl--0 pr--0 pt--0 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":1440}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

              <Title className="title-box fs--48" content={"Plnění klimatizací chladivem R134a<br>"}>
              </Title>

              <Text className="text-box" content={"Provádíme servis,čištění,plnění i dezinfekci klimatizací na osobních vozidlech dodávkách i strojích<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"&nbsp;"}>
              </Subtitle>

              <Title className="title-box fs--48" content={"Detekce úniku chladiva a netesnosti klimatizace"}>
              </Title>

              <Text className="text-box" content={"Odhalíme&nbsp; najdeme a případně i odstraníme netesnost klimatizace na vašem vozidle<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

              <Title className="title-box fs--48" content={"Výměna autoskla"}>
              </Title>

              <Text className="text-box" content={"Výměna autoskel na osobních i užitkových vozidlech<br>Z pojištění zdarma celou škodu na vašem skle vyřídíme s pojišťovnou a opravíme úplně zdarma.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--08 pt--08" menu={true} name={"vxbpf7iy7hd"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"Autoservis Bláža"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--50 pr--20 pt--50" name={"informace"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --right el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" animS={"3"} style={{"maxWidth":""}}>
              
              <Image src={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-2_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --left pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"5"} style={{"maxWidth":526}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: white;\">Přes 15 let zkušeností</span>"}>
              </Subtitle>

              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Profesionální mechanici s léty zkušeností<br></span>"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: white;\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

              <Button className="btn-box btn-box--shape5" url={"https://autoservisblaza.cz/sluzby"} href={"https://autoservisblaza.cz/sluzby"} content={"Nabídka služeb"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1rkryxn css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontaktni-formular/"} use={"url"} href={"https://autoservisblaza.cz/kontaktni-formular/"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Autoservis Bláža Ostrava - Radvanice&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Pro Vaše auto uděláme vše, co budeme moct</span>.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">O nás</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-1--95);\">Jsme Malý a nezávislý autoservis osobních i užitkových vozidel. Měření a seřízení geometrie kol Servis čištění a plnění klimatizací Rychloservis Pneuservis Nástřiky podvozku a dutin Strojní regenerace filtru pevných částic DPF a jiné.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-custom-1--95);\">&nbsp;Lihovarská 1378/11, Ostrava - Radvanice Areál Bánských strojíren ( BASTRO ) Tel: +420 777 321 587</span>&nbsp;<span style=\"color: white;\">&nbsp;</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white;\">IČ:63471710</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}